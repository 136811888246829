/** @format */

import { DEFAULT_ELEMENTS_PER_PAGE } from './variables';

import queryString from 'query-string';

export const usersOptionsFilter = (params = {}) => ({
  ...params,
  limit: 10000,
  filter: {
    sort: 'username:asc',
    ...params.filter,
    not_roles: ['admin', 'system'],
  },
});

export const generateUrl = (url, params = {}) => {
  const qs = queryString.stringify(params);
  return `${url}?${qs}`;
};

export const generateSimpleQueryV2 = params => {
  const filters = {};
  if ('filter' in params) {
    Object.keys(params.filter).map(f => {
      if (params.filter[f]) {
        filters[f] = params.filter[f];
        return filters[f];
      }
      return false;
    });
  }

  return {
    ...filters,
    offset: params.offset,
    limit:
      params.limit !== null && params.limit !== undefined
        ? params.limit
        : DEFAULT_ELEMENTS_PER_PAGE,
  };
};

export const generateQueryString = params => {
  let filters = [];

  if ('filter' in params) {
    filters = Object.keys(params.filter).map(f => {
      if (params.filter[f]) {
        return `${f} iLike %${params.filter[f]}%`;
      }
      return false;
    });
  }

  return {
    filter: filters.length > 0 ? filters.join(',') : undefined,
    offset: params.offset,
    limit: params.limit,
  };
};

export const useThisQueryString = (url, params) => `${url}?${params.qs}`;

export const objectToQueryString = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  const keys = Object.keys(obj);
  const keyValuePairs = keys.map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join('&');
};
