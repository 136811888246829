/** @format */
/* eslint-disable react/display-name */

import React, { Fragment, useEffect } from 'react';
import { Divider, Header, Label, Menu, Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { userHasRole } from '../../lib/auth';
import {
  DeviceProfiles,
  DevicesTable,
  DevicesTableSharedBy,
  DevicesTableSharedWith,
  DevicesTableMulticastGroups,
  DevicesMassiveOperations,
} from '../../containers';

import { fetchUsers } from '../../actions/users';
import {
  fetchDevices,
  fetchDevicesSharedBy,
  fetchDevicesSharedWith,
} from '../../actions/devices';
import { fetchMulticastGroups } from '../../actions/multicastGroups';
import {
  fetchApplications,
  destroyApplications,
} from '../../actions/applications';
import { updateTab } from '../../actions/tabs';
import { fetchDeviceProfileList } from '../../actions/deviceProfiles';

import {
  NEW_IMPORT_RELEASE_DATE,
  DEFAULT_ELEMENTS_PER_PAGE,
} from '../../lib/variables';

import './Devices.css';

const Devices = props => {
  // const me = useSelector(state => state.me);
  const users = useSelector(state => state.users);
  const applications = useSelector(state => state.applications);
  const devices = useSelector(state => state.devices);
  const devicesSharedBy = useSelector(state => state.devicesSharedBy);
  const devicesSharedWith = useSelector(state => state.devicesSharedWith);
  const index = useSelector(state => state.tabs.devices.index);
  const multicastGroups = useSelector(state => state.multicastGroups);
  const { rows: profiles, isLoading: profilesIsLoading } = useSelector(
    state => state.deviceProfiles,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (userHasRole('admin')) {
      if (users && !('size' in users))
        dispatch(fetchUsers({ qs: 'not_roles[]=system&not_roles[]=admin' }));
    }
    if (applications && !('size' in applications))
      dispatch(fetchApplications());
    if (devices && !('size' in devices))
      dispatch(fetchDevices({ qs: `limit=${DEFAULT_ELEMENTS_PER_PAGE}` }));
    if (devicesSharedBy && !('size' in devicesSharedBy))
      dispatch(
        fetchDevicesSharedBy({ qs: `limit=${DEFAULT_ELEMENTS_PER_PAGE}` }),
      );
    if (devicesSharedWith && !('size' in devicesSharedWith))
      dispatch(
        fetchDevicesSharedWith({ qs: `limit=${DEFAULT_ELEMENTS_PER_PAGE}` }),
      );
    if (multicastGroups && !('size' in multicastGroups))
      dispatch(fetchMulticastGroups());

    return () => {
      dispatch(destroyApplications());
    };
  }, []);

  useEffect(() => {
    if (profiles.length === 0 && !profilesIsLoading) {
      dispatch(fetchDeviceProfileList());
    }
  }, [profiles]);

  const handleTabChange = (e, { activeIndex }) => {
    e.preventDefault();
    dispatch(updateTab({ devices: { index: activeIndex || 0 } }));
  };

  const panes = [
    {
      displayName: 'myDevices',
      menuItem: (
        <Menu.Item key="myDevices">
          devices list
          {devices && 'size' in devices && (
            <Label color={index == 0 ? 'teal' : 'grey'}>{devices.size}</Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <DevicesTable />
        </Tab.Pane>
      ),
    },
    {
      displayName: 'sharedWith',
      menuItem: (
        <Menu.Item key="sharedWith">
          shared with
          {devicesSharedWith && 'size' in devicesSharedWith && (
            <Label color={index == 1 ? 'teal' : 'grey'}>
              {devicesSharedWith.size}
            </Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <DevicesTableSharedWith />
        </Tab.Pane>
      ),
    },
    {
      displayName: 'sharedBy',
      menuItem: (
        <Menu.Item key="sharedBy">
          shared by
          {devicesSharedBy && 'size' in devicesSharedBy && (
            <Label color={index == 2 ? 'teal' : 'grey'}>
              {devicesSharedBy.size}
            </Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <DevicesTableSharedBy />
        </Tab.Pane>
      ),
    },
    {
      displayName: 'multicast',
      menuItem: (
        <Menu.Item key="multicast">
          multicast groups
          {multicastGroups && 'size' in multicastGroups && (
            <Label color={index == 3 ? 'teal' : 'grey'}>
              {multicastGroups.size}
            </Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <DevicesTableMulticastGroups />
        </Tab.Pane>
      ),
    },
    {
      displayName: 'profile',
      menuItem: (
        <Menu.Item key="profile">
          profiles
          {profiles && 'size' in profiles && (
            <Label color={index == 4 ? 'teal' : 'grey'}>
              {profiles.size || 0}
            </Label>
          )}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <DeviceProfiles profiles={profiles} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'import_new',
        content: (
          <span>
            import/export
            {moment().diff(moment(NEW_IMPORT_RELEASE_DATE), 'days') <= 60 && (
              <span className="advice-new">new</span>
            )}
          </span>
        ),
      },
      render: () => (
        <Tab.Pane>
          <DevicesMassiveOperations />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Fragment>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Devices
      </Header>
      <Divider clearing />
      <Tab
        menu={{ attached: true, tabular: true, className: 'wrapped' }}
        panes={panes}
        activeIndex={index}
        onTabChange={handleTabChange}
      />
    </Fragment>
  );
};

Devices.propTypes = {
  location: PropTypes.object,
};

export { Devices };
