/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Header,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import {
  destroyGatewaysSharedBy,
  fetchGatewaysSharedBy,
} from '../../actions/gateways';
import { deleteGatewayShare } from '../../actions/gatewayShare';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import { userHasRole } from '../../lib/auth';
import {
  EMPTY_TABLE_MESSAGE,
  formatKeys,
  formatNormalString,
  searchMethod,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE, PAGES_PATH } from '../../lib/variables';
import SearchBox from '../../ui/SearchBox';

const mapStateToProps = state => ({
  gatewaysSharedBy: state.gatewaysSharedBy,
  column: state.devices.column,
  direction: state.devices.direction,
  users: state.users,
  usersOptions: state.usersOptions.options || [],
});

const mapDispatchToProps = dispatch => ({
  fetchGatewaysSharedBy: (params = { qs: '' }) => {
    dispatch(fetchGatewaysSharedBy(params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  deleteGatewayShare: (gateway, guest) => {
    dispatch(deleteGatewayShare(gateway, guest));
  },
  destroyGatewaysSharedBy: () => {
    dispatch(destroyGatewaysSharedBy());
  },
});

class GwTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 3,
          label: 'Label',
          name: 'label',
          type: 'text',
        },
        {
          width: 4,
          label: 'GatewayId',
          name: 'gid',
          type: 'text',
        },
      ],
      searchModel: {},
      modalOpen: false,
      share: null,
      gatewayId: null,
      check: null,
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      modalType: null,
      device: null,
      deveui: null,
      checkOwner: false,
      selected: [],
      owners: [],
      owner: null,
      devices: [],
      column: null,
      direction: null,
      search: '',
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      users: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchGatewaysSharedBy({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { gatewaysSharedBy, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * gatewaysSharedBy.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;

        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newFormGroup = [...this.state.formGroup];
        const newSearchModel = { ...searchModel };

        const formIdx = newFormGroup.map(x => x.name).indexOf(name);
        switch (value) {
          case '':
            delete newSearchModel[name];
            break;

          default:
            switch (name) {
              case 'gid':
                newSearchModel[name] = encodeURIComponent(
                  formatNormalString(value, ':'),
                );
                break;
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }
        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.fetchGatewaysSharedBy({ qs: limit > 0 ? `limit=${limit}` : '' });
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({ qs: 'limit=10000' });
    }
  }

  // componentWillUnmount() {
  //   this.props.destroyGatewaysSharedBy();
  // }

  handleClose() {
    this.setState({
      modalOpen: false,
      share: null,
      GuestId: null,
      gatewayId: null,
      check: null,
    });
  }

  handleDeleteModal(share) {
    this.setState({ modalOpen: true, share, gatewayId: share.GatewayId });
  }

  handleDelete() {
    const { share } = this.state;
    this.props.deleteGatewayShare(share.id);
    this.setState({
      modalOpen: false,
      gateway: null,
      gatewayId: null,
      check: null,
    });
    setTimeout(() => {
      this.props.fetchGatewaysSharedBy();
    }, 500);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { formGroup, share, gatewayId, check } = this.state;
    const { usersOptions, gatewaysSharedBy } = this.props;

    return (
      <Fragment>
        {formGroup.length > 0 && (
          <SearchBox
            formGroup={formGroup}
            usersOptions={
              userHasRole('admin') && usersOptions.length ? usersOptions : []
            }
            startSearch={this.searchBox.handleSearch}
            updateSearch={this.searchBox.handleSearchChange}
          />
        )}
        {gatewaysSharedBy.rows && gatewaysSharedBy.rows.length > 0 ? (
          <Table sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={this.searchBox.handleSorted('label')}
                  onClick={this.searchBox.handleSort('label')}
                >
                  Label
                </Table.HeaderCell>

                <Table.HeaderCell key="headerSharing">Sharing</Table.HeaderCell>

                <Table.HeaderCell key="headerGatewayId">
                  Gateway ID
                </Table.HeaderCell>

                <Table.HeaderCell key="headerType">Public</Table.HeaderCell>

                <Table.HeaderCell key="headerTools" textAlign="center">
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {idx(gatewaysSharedBy, accessory =>
                accessory.rows.map(row => (
                  <Table.Row key={`row-${row.id}`}>
                    {userHasRole('admin') ? (
                      <Table.Cell key={`row-${row.id}-label`}>
                        <Link
                          to={`${PAGES_PATH}/gateway/${idx(
                            row,
                            _ => _.gateway.id,
                          )}`}
                        >
                          <strong>{row.label || 'no name'}</strong>
                        </Link>
                      </Table.Cell>
                    ) : (
                      <Table.Cell key={`row-${row.id}-label`}>
                        <strong>{idx(row, _ => _.label)}</strong>
                      </Table.Cell>
                    )}

                    <Table.Cell key={`row-${row.id}-sharing`}>
                      {userHasRole('admin') ? (
                        <>
                          <Link to={`user/${row.gateway.owner.id}`}>
                            {idx(row, _ => _.gateway.owner.username)}
                            <FontAwesomeIcon
                              icon="arrow-left"
                              className="icon"
                            />{' '}
                          </Link>
                          <Link to={`user/${row.guest.id}`}>
                            {idx(row, _ => _.guest.username)}
                          </Link>
                        </>
                      ) : (
                        <>
                          {idx(row, _ => _.gateway.owner.username)}{' '}
                          <FontAwesomeIcon icon="arrow-left" className="icon" />{' '}
                          {idx(row, _ => _.guest.username)}
                        </>
                      )}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-gatewayId`}>
                      {idx(row, _ => formatKeys(_.GatewayId))}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-public`}>
                      {idx(row, _ => _.gateway.public) ? (
                        <Label size="mini" color="green">
                          Public
                        </Label>
                      ) : (
                        <Label size="mini" color="blue">
                          Private
                        </Label>
                      )}
                    </Table.Cell>

                    <Table.Cell key={`row-${row.id}-tools`} textAlign="center">
                      <Link to="#" onClick={() => this.handleDeleteModal(row)}>
                        <FontAwesomeIcon icon="trash-alt" />
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )),
              )}
            </Table.Body>
          </Table>
        ) : (
          EMPTY_TABLE_MESSAGE
        )}

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>
              This action will remove a share from{' '}
              {idx(share, _ => _.gateway.owner.username)}
            </h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the Gateway ID</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {gatewayId}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={gatewayId !== check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>
        <Container textAlign="center">
          {/*
              Pagination:
              SemanticUI  => https://react.semantic-ui.com/addons/pagination/
              Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
              */}
          {gatewaysSharedBy &&
            gatewaysSharedBy.count > gatewaysSharedBy.limit && (
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={
                  parseInt(
                    gatewaysSharedBy.offset / gatewaysSharedBy.limit,
                    10,
                  ) + 1
                }
                totalPages={Math.ceil(
                  gatewaysSharedBy.count / gatewaysSharedBy.limit,
                )}
                onPageChange={this.searchBox.handlePageChange}
              />
            )}
        </Container>
      </Fragment>
    );
  }
}

GwTable.propTypes = {
  gatewaysSharedBy: PropTypes.object,
  fetchGatewaysSharedBy: PropTypes.func,
  deleteGatewayShare: PropTypes.func,
  destroyGatewaysSharedBy: PropTypes.func,
  destroyGatewayShare: PropTypes.func,
  offset: PropTypes.object,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  usersOptions: PropTypes.object,
};

const GatewaysTableSharedBy = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GwTable),
);

export { GatewaysTableSharedBy };
