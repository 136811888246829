/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Header,
  Input,
  Label,
  Loader,
  Modal,
  Grid,
} from 'semantic-ui-react';
import SVG from 'react-inlinesvg';
import idx from 'idx';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { DeviceDownlink } from '..';
import {
  destroyDevices,
  fetchDevices,
  searchDevices,
  orderDevices,
} from '../../actions/devices';
import {
  fetchApplications,
  destroyApplications,
} from '../../actions/applications';
import { deleteDevice, destroyDevice } from '../../actions/device';
import { userHasRole } from '../../lib/auth';
import { PAGES_PATH, DEFAULT_ELEMENTS_PER_PAGE } from '../../lib/variables';
import { fetchUsers, fetchUsersOptions } from '../../actions/users';
import DefaultTable from '../../components/Utils/DefaultTable';
import DefaultButton from '../../components/Utils/DefaultButton';
import ButtonExport from '../DeviceManager/DeviceExport/ButtonExport';
import ExportDevicesDownloadLink from '../DeviceManager/DeviceExport/ExportDevicesDownloadLink';

const mapStateToProps = state => {
  const { devices } = state;
  const { searchText, rows } = devices;
  return {
    devices: {
      ...state.devices,
      rows:
        rows &&
        rows.filter(
          device =>
            device.label && device.label.toLowerCase().includes(searchText),
        ),
    },
    applications: state.applications,
    column: state.devices.column,
    direction: state.devices.direction,
    users: state.users,
    usersOptions: state.usersOptions.options || [],
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDevices: (params = { qs: `limit:${DEFAULT_ELEMENTS_PER_PAGE}` }) => {
    dispatch(fetchDevices(params));
  },
  searchDevices: text => {
    dispatch(searchDevices(text));
  },
  orderDevices: clickedColumn => {
    dispatch(orderDevices(clickedColumn));
  },
  deleteDevice: device => {
    dispatch(deleteDevice(device));
  },
  destroyDevices: () => {
    dispatch(destroyDevices());
  },
  destroyDevice: () => {
    dispatch(destroyDevice());
  },
  fetchApplications: params => {
    dispatch(fetchApplications(params));
  },
  destroyApplications: () => {
    destroyApplications();
  },
  fetchUsers: (username, params = { qs: '' }) => {
    dispatch(fetchUsers(username, params));
  },
  fetchUsersOptions: () => {
    dispatch(fetchUsersOptions());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formGroup: [],
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      modalOpen: false,
      modalType: null,
      device: null,
      deveui: null,
      check: null,
      checkOwner: false,
      selected: [],
      owners: [],
      owner: null,
      devices: [],
      column: null,
      direction: null,
      search: '',
      applications: [],
      users: [],
      admin: userHasRole('admin'),
      filtersExport: {},
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleDownlinkModal = this.handleDownlinkModal.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.fetchDevices({
      qs: limit > 0 ? `limit=${limit}&sort=id:asc` : 'sort=id:asc',
    });
    this.props.fetchApplications({ qs: 'sort=label:asc&limit=100000' });
    if (userHasRole('admin')) {
      this.props.fetchUsersOptions();
      this.props.fetchUsers({
        qs:
          'sort=username:asc&limit=10000&not_roles[]=system&not_roles[]=admin',
      });
    }
    this.manageVisibleFilters();
  }

  manageVisibleFilters() {
    const nextFormGroup = [
      {
        width: 3,
        label: 'Label',
        name: 'label',
        type: 'text',
      },
      {
        width: 4,
        label: 'Application_Uid',
        name: 'appid',
        type: 'select',
      }, // il filtro per application deve tenere conto anche dell uid quando user === admin
      {
        width: 3,
        label: 'Deveui',
        name: 'deveui',
        type: 'text',
      },
      {
        width: 2,
        label: 'Type',
        name: 'type',
        type: 'select',
      },
      {
        width: 2,
        label: 'Status',
        name: 'activated',
        type: 'select',
      },
    ];
    if (userHasRole('admin')) {
      nextFormGroup.splice(1, 0, {
        width: 4,
        label: 'Owner',
        name: 'uid',
        type: 'select',
      });
    }

    this.setState({
      ...this.state,
      formGroup: nextFormGroup,
    });
  }

  componentWillUnmount() {
    this.props.destroyDevices();
    this.props.destroyDevices();
    destroyDevice();
    this.props.searchDevices('');
  }

  fetch(qs) {
    this.props.fetchDevices(qs);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      modalType: 'delete',
      device: null,
      deveui: null,
      check: null,
      downlink: null,
      deviceId: null,
      applicationId: null,
    });
  }

  handleDeleteModal(device) {
    this.setState({
      modalOpen: true,
      modalType: 'delete',
      device,
      deveui: device.deveui,
      deviceId: null,
      applicationId: null,
    });
  }

  handleDownlinkModal(device) {
    this.setState({
      modalOpen: true,
      modalType: 'downlink',
      device,
      deveui: device.deveui,
      deviceId: device.id,
      applicationId: device.ApplicationId,
    });
  }

  handleDelete() {
    const { device } = this.state;
    this.props.deleteDevice(device.id);
    this.setState(
      {
        modalOpen: false,
        modalType: 'null',
        device: null,
        deveui: null,
        check: null,
        downlink: null,
        deviceId: null,
        applicationId: null,
      },
      () => this.props.fetchDevices(),
    );
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleCheck(id, owner) {
    const { owners, selected } = this.state;
    const i = selected.indexOf(id);
    const c = owners.indexOf(owner);
    if (i === -1) {
      selected.push(id);
      owners.push(owner);
    } else {
      selected.splice(i, 1);
      owners.splice(c, 1);
    }
    const check = [...new Set(owners)];
    this.setState({
      selected,
      owner: check[0],
      checkOwner: !(check.length > 1),
    });
  }

  onSearch = e => {
    const {
      // deveui = null,
      label = null,
      type = null,
      activated = null,
      uid = null,
      appid = null,
    } = e;

    this.setState({
      ...this.state,
      filtersExport: {
        // ...(deveui ? { deveui } : {}),
        ...(label ? { label } : {}),
        ...(type ? { type } : {}),
        ...(activated ? { activated } : {}),
        ...(uid ? { uid } : {}),
        ...(appid ? { appid } : {}),
      },
    });
  };

  render() {
    const {
      deveui,
      check,
      checkOwner,
      selected,
      owner,
      formGroup,
      filtersExport,
    } = this.state;
    const { devices, applications, users, usersOptions } = this.props;
    return (
      <Fragment>
        <Grid.Row style={{ marginBottom: '2em' }}>
          <Grid.Column>
            <DefaultButton
              path={`${PAGES_PATH}/device/add`}
              icon={'plus-circle'}
              desc={'Add new Device'}
            />
            <DefaultButton
              disabled={selected.length === 0}
              path={{
                pathname: `${PAGES_PATH}/device/share`,
                state: { devices: selected, UserId: owner },
              }}
              icon={'share-alt'}
              desc={'Share Device(s)'}
            />
            <DefaultButton
              disabled={true}
              path={{
                pathname: `${PAGES_PATH}/application/add-to`,
                state: { devices: selected, UserId: owner },
              }}
              icon={'rocket'}
              desc={'Add to Application'}
            />
            <DefaultButton
              disabled={!!(selected.length === 0 || checkOwner === false)}
              path={{
                pathname: `${PAGES_PATH}/multicast/add`,
                state: { devices: selected, UserId: owner },
              }}
              icon={'satellite-dish'}
              desc={' Add to Multicast Group'}
            />
            <ButtonExport filters={filtersExport} />
          </Grid.Column>
        </Grid.Row>
        <>
          <ExportDevicesDownloadLink />
        </>
        <div
          style={{ display: idx(devices, _ => _.isLoading) ? 'none' : 'block' }}
        >
          <DefaultTable
            headers={[
              { name: '', key: 'checkbox' },
              {
                sortable: true,
                name: 'Label',
                sortBy: 'label',
                key: 'label',
              },
              {
                sortable: true,
                name: 'Owner',
                sortBy: 'UserId',
                admin: this.state.admin,
                key: 'owner',
              },
              { name: 'Application', key: 'application' },
              {
                sortable: true,
                name: 'DevEui',
                sortBy: 'deveui',
                key: 'deveui',
              },
              {
                sortable: true,
                name: 'Type',
                sortBy: 'type',
                key: 'type',
              },
              { name: 'Status', key: 'status' },
              {
                name: 'Actions',
                actions: ['downlink', 'edit', 'delete'],
                key: 'actions',
              },
            ]}
            path={'device'}
            selected={selected}
            users={users}
            applications={applications}
            handleCheck={this.handleCheck}
            handleDownlinkModal={this.handleDownlinkModal}
            formGroup={formGroup}
            usersOptions={usersOptions}
            handleDeleteModal={this.handleDeleteModal}
            fetch={this.fetch}
            items={devices}
            onSearch={this.onSearch}
          />
        </div>

        <div
          className="div_full_with text_center"
          style={{ display: idx(devices, _ => _.isLoading) ? 'block' : 'none' }}
        >
          <Loader active inline content="Loading" />
        </div>
        {idx(this.state, _ => _.modalType) === 'delete' &&
          idx(this.state, _ => _.deveui) && (
            <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
              basic
              size="small"
            >
              <div style={{ width: '40%' }}>
                <SVG src="/assets/images/robotRYS.svg" />
              </div>
              <Header as="h1" content="Are you sure??" />
              <Modal.Content>
                <h3>
                  This action will delete this device and all related shares.
                </h3>
                <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
                <p>To proceed, please fill the field with the device Eui</p>
                <Label
                  size="big"
                  color="orange"
                  style={{ marginBottom: '2em' }}
                >
                  {idx(this.state, _ => _.deveui)}
                </Label>
                <div>
                  <Input
                    name="check"
                    placeholder="deveui..."
                    width={8}
                    onChange={this.handleChange}
                  />
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={this.handleDelete}
                  color="red"
                  disabled={deveui !== check}
                >
                  Proceed
                </Button>
                <Button onClick={this.handleClose}>Cancel</Button>
              </Modal.Actions>
            </Modal>
          )}

        {idx(this.state, _ => _.modalType) === 'downlink' && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            size="small"
          >
            <Header as="h1" content="Downlink" />
            <Modal.Content>
              <DeviceDownlink
                pollingActive={true}
                device={this.state.device}
                deveui={this.state.deveui}
                type="mini"
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleClose}>Close</Button>
            </Modal.Actions>
          </Modal>
        )}
        {false && (
          <ReactJson
            name={null}
            displayObjectSize={false}
            displayDataTypes={false}
            src={this.state}
          />
        )}
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  devices: PropTypes.object,
  postDownlink: PropTypes.func,
  fetchDevices: PropTypes.func,
  destroyDevices: PropTypes.func,
  destroyDevice: PropTypes.func,
  deleteDevice: PropTypes.func,
  fetchApplications: PropTypes.func,
  fetchUsersOptions: PropTypes.func,
  fetchUsers: PropTypes.func,
  searchDevices: PropTypes.func,
  applications: PropTypes.object,
  users: PropTypes.object,
  usersOptions: PropTypes.array,
};

const DevicesTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { DevicesTable };
