/** @format */

import { toast } from 'react-semantic-toasts';
import devLog from './devLog';
import { DEFAULT_ELEMENTS_PER_PAGE } from './variables';

export const DEVICE_NEVER_TALK_STATUS = 444;
export const EMPTY_STRING_VALUE = '---';
export const EMPTY_TABLE_MESSAGE = 'Nothing to show.';

export const resolve = (path, obj) =>
  path
    .split('.')
    .reduce((prev, curr) => (prev ? prev[curr] : null), obj || this);

export const cleanObject = (obj, allowed = []) => {
  Object.keys(obj)
    .filter(key => !allowed.includes(key))
    .forEach(key => delete obj[key]);

  return obj;
};
/* eslint-disable */
export const formatBytes = (str, bytesGroupedBy) => {
  const regexToApply = new RegExp('(.{' + bytesGroupedBy + '})', 'g');
  const formattedString = str
    .replace(/[^\dA-Fa-f]/g, '')
    .replace(regexToApply, '$1 ')
    .trim();

  return formattedString;
};

export const checkPadding = (str, padding) => {
  return str.toString().padStart(padding, 0);
};

export const formatKeys = str => {
  const regexToApply = new RegExp('(.{2})', 'g');
  const formattedString = str
    .replace(/[^\dA-Fa-f]/g, '')
    .replace(regexToApply, '$1:')
    .trim()
    .toUpperCase()
    .slice(0, -1);

  return formattedString;
};

export const getProfileLabel = (id, profiles) => {
  const o = profiles.filter(profile => {
    return profile.id === id;
  });
  return o.length ? o[0].label : '';
};

export const formatNormalString = (str, charToFind) => {
  const regexToApply = new RegExp(charToFind, 'g');
  return str ? str.replace(regexToApply, '').toLowerCase() : '';
};
/* eslint-disable */
export const isCoordinate = (value, field) => {
  switch (field) {
    case 'alt':
      return validateCoordinates(7000, -7000, value);
    case 'lat':
      return validateCoordinates(90, -90, value);
    case 'lng':
      return validateCoordinates(180, -180, value);
    default:
      break;
  }
};

const validateCoordinates = (max, min, value) => value <= max && value >= min;

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = password =>
  password !== null && password.length >= 8 && password.length <= 255;

export const sortedIpArr = (ipArr, asc) => {
  const sortedIpArr = ipArr.sort(
    (a, b) =>
      a.split('.')[0] - b.split('.')[0] ||
      a.split('.')[1] - b.split('.')[1] ||
      a.split('.')[2] - b.split('.')[2] ||
      a.split('.')[3] - b.split('.')[3],
  );

  if (asc) return sortedIpArr;
  else return sortedIpArr.reverse();
};

export const hasComma = value => /-?\d+\.\d+$/g.test(value);

export const coupleOfBytesLengths = {
  DEVADDR: 4,
  DEVEUI: 8,
  JOINEUI: 8,
  APPKEY: 16,
  GATEWAYID: 8,
  APPSKEY: 16,
  NWKSKEY: 16,
  SUBNET: 5,
};

export const generatePlaceholder = length =>
  `Enter ${length} byte hex value (ex.${new Array(length)
    .fill('XX')
    .toString()
    .replace(/[,]/g, ' ')})`;

export const orderItemsByField = (field, items) => {
  const sortedItems = items.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });

  return sortedItems;
};

export const formatOptions = (dataToFormat, dropdown) => {
  devLog(dropdown);
  switch (dropdown) {
    case 'Visibility':
      return dataToFormat.map(item => ({
        key: `${dropdown}_${item}`,
        text: item,
        value: (item === 'public').toString(), //if no string will be not display anything if false on select
      }));

    case 'Protocol':
    case 'Max Tx Power':
    case 'Transmission Power':
      return dataToFormat.map(item => ({
        key: `${dropdown}_${item}`,
        text: '' + item,
        value: item,
      }));
    case 'Supports Class B':
    case 'Supports LBT':
      return dataToFormat.map(item => ({
        key: `${dropdown}_${item}`,
        text: '' + item,
        value: (item === 'true').toString(),
      }));
    case 'Band':
      return dataToFormat.map(item => ({
        key: `${dropdown}_${item}`,
        text: item,
        value: item,
      }));
    case 'Application':
      return dataToFormat.map(({ id, label, appid }) => ({
        key: `${dropdown}_${id}_${label}`,
        text: label,
        value: appid,
      }));
    case 'Application_Uid':
      return dataToFormat.map(({ id, label, appid, UserId }) => ({
        key: `${dropdown}_${id}_${label}`,
        text: label,
        value: `${appid}-${UserId}`,
      }));

    case 'Owner':
    case 'Standard User':
    case 'Shared With':
      return dataToFormat.map(({ id, username }) => ({
        key: `${dropdown}_${id}_${username}`,
        text: username,
        value: id,
      }));

    case 'Profile':
    case 'Multicast Groups':
      return dataToFormat.map(({ label, id }) => ({
        key: `${dropdown}_${label}`,
        text: label,
        value: id,
      }));
    case 'BasicstationConfigurations':
      return dataToFormat.map(id => ({
        key: `${id}`,
        text: id,
        value: id,
      }));
    default:
      return [];
  }
};

export const cleaveOptions = {
  delimiter: ':',
  uppercase: true,
};

export function clean(obj) {
  const isNaN = maybeNaN => maybeNaN != maybeNaN;
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      isNaN(obj[propName])
    ) {
      delete obj[propName];
    }
  }

  return obj;
}
/*    ---- Toast Error ---- */
/**
 * Display the error message as an Alert
 *
 * @param {string} [title] The header of the toast, default: Warning!
 * @param {object} msg The content of the toast
 * @param {number} [time] Duration to keep the toast open, 0 to wait until closed by the user, default: 5000
 *
 */
export const toastError = (msg, title = 'Warning!', time = 5000) => {
  let description = 'Oops, Something Went Wrong';
  if (typeof msg === 'string') {
    description = msg;
  } else if (msg && msg.hasOwnProperty('message')) {
    description = msg.message;
  }

  toast({
    type: 'error',
    title,
    description,
    animation: 'fly left',
    time: time,
  });
};

/*    ---- Toast Warning ---- */
/**
 * Display the warning message as an Alert
 *
 * @param {string} [title] The header of the toast, default: Congratulations!
 * @param {string} description The content of the toast
 * @param {number} [time] Duration to keep the toast open, 0 to wait until closed by the user, default: 5000
 *
 */
export const toastWarning = (
  description,
  title = 'Congratulations!',
  time = 5000,
) => {
  toast({
    type: 'warning',
    title: title,
    description: description,
    animation: 'fly left',
    time: time,
  });
};

/*    ---- Toast Success ---- */
/**
 * Display the success message as an Alert
 *
 * @param {string} [title] The header of the toast, default: Congratulations!
 * @param {string} description The content of the toast
 * @param {number} [time] Duration to keep the toast open, 0 to wait until closed by the user, default: 5000
 *
 */
export const toastSuccess = (
  description,
  title = 'Congratulations!',
  time = 5000,
) => {
  toast({
    type: 'success',
    title: title,
    description: description,
    animation: 'fly left',
    time: time,
  });
};

// ** SEARCH METHOD

export const searchMethod = {
  genQs: (searchModel, sortModel, offset, limit) => {
    return genFiltering(searchModel).concat(
      genSorting(sortModel).concat(genPagination(offset, limit)),
    );
  },
};
const genFiltering = searchModel => {
  const queryParams = [];
  for (let search in searchModel) {
    if (search === 'appid' && searchModel[search].split('-').length > 1) {
      let aSrc = searchModel[search].split('-');
      queryParams.push(encodeURIComponent('appid') + '=' + aSrc[0]);
      if (!searchModel.uid) {
        queryParams.push(encodeURIComponent('uid') + '=' + aSrc[1]);
      }
    } else {
      queryParams.push(encodeURIComponent(search) + '=' + searchModel[search]);
    }
  }
  // debugger
  return queryParams;
};

const genSorting = sortModel => {
  const queryParams = [];
  if (sortModel && sortModel.direction)
    queryParams.push('sort=' + sortModel.label + ':' + sortModel.direction);
  // debugger
  return queryParams;
};

const genPagination = (offset, limit = DEFAULT_ELEMENTS_PER_PAGE) => {
  const queryParams = [];

  if (offset) queryParams.push('offset=' + offset);
  if (limit > 0) queryParams.push('limit=' + limit);

  // debugger
  return queryParams;
};

// ** SEARCH METHOD
