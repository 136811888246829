/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Header,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import {
  deleteUserProfile,
  destroyUserProfile,
} from '../../actions/userProfile';
import {
  destroyUserProfilesList,
  fetchUserProfilesList,
} from '../../actions/userProfiles';
import {
  EMPTY_STRING_VALUE,
  EMPTY_TABLE_MESSAGE,
  searchMethod,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE, PAGES_PATH } from '../../lib/variables';
import SearchBox from '../../ui/SearchBox';

const mapStateToProps = state => ({
  userProfileList: state.userProfilesList,
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfilesList: (params = {}) => {
    dispatch(fetchUserProfilesList(params));
  },
  deleteUserProfile: userProfile => {
    dispatch(deleteUserProfile(userProfile));
  },
  destroyUserProfilesList: () => {
    dispatch(destroyUserProfilesList());
  },
  destroyUserProfile: () => {
    dispatch(destroyUserProfile());
  },
});

const CURR_PATH = 'user-profile';

class UsersProfileListTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formGroup: [
        {
          width: 2,
          label: 'ID',
          name: 'id',
          type: 'number',
        },
        {
          width: 3,
          label: 'Label',
          name: 'label',
          type: 'text',
        },
      ],
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
      modalOpen: false,
      id: null,
      label: null,
      check: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchUserProfilesList({ qs });
      },

      handlePageChange: (e, { activePage }) => {
        const { userProfileList, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * userProfileList.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;

        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newFormGroup = [...this.state.formGroup];
        const newSearchModel = { ...searchModel };

        const formIdx = newFormGroup.map(x => x.name).indexOf(name);
        switch (value) {
          case '':
            delete newSearchModel[name];
            break;

          default:
            switch (name) {
              case 'expiry':
                newSearchModel[name] = encodeURIComponent(
                  moment(value).toISOString(),
                );
                break;
              default:
                newSearchModel[name] = encodeURIComponent(value);
            }
        }
        if (formIdx >= 0) newFormGroup[formIdx].selected = value;
        this.setState({
          searchModel: newSearchModel,
          formGroup: newFormGroup,
        });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    setTimeout(
      () =>
        this.props.fetchUserProfilesList({
          qs: limit > 0 ? `limit=${limit}` : '',
        }),
      300,
    );
  }

  componentWillUnmount() {
    // this.props.destroyUserProfilesList();
    this.props.destroyUserProfile();
  }

  handleClose() {
    this.setState({ modalOpen: false, label: null, check: null });
  }

  handleDeleteModal(id, label) {
    this.setState({ modalOpen: true, id, label });
  }

  handleDelete() {
    const { id, limit } = this.state;
    this.props.deleteUserProfile(id);
    this.setState({
      modalOpen: false,
      id: null,
      label: null,
      check: null,
    });
    setTimeout(() => {
      this.props.fetchUserProfilesList({
        qs: limit > 0 ? `limit=${limit}` : '',
      });
    }, 300);
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  render() {
    const { userProfileList } = this.props;
    const { formGroup } = this.state;

    const profileList = { ...userProfileList };

    return (
      <Fragment>
        <Button
          as={Link}
          key="addNew"
          compact
          icon
          color="red"
          labelPosition="left"
          to={`${PAGES_PATH}/${CURR_PATH}/add`}
        >
          <FontAwesomeIcon
            icon="plus-circle"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Add profile
        </Button>

        {formGroup.length && (
          <SearchBox
            formGroup={formGroup}
            startSearch={this.searchBox.handleSearch}
            updateSearch={this.searchBox.handleSearchChange}
          />
        )}

        <div style={{ width: '100%', overflowX: 'auto', marginTop: '1em' }}>
          {profileList.rows && profileList.rows.length ? (
            <Table sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('id')}
                    onClick={this.searchBox.handleSort('id')}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('label')}
                    onClick={this.searchBox.handleSort('label')}
                  >
                    Label
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('role')}
                    onClick={this.searchBox.handleSort('role')}
                  >
                    Role
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('maxdevices')}
                    onClick={this.searchBox.handleSort('maxdevices')}
                  >
                    Maxdevices
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('maxgateways')}
                    onClick={this.searchBox.handleSort('maxgateways')}
                  >
                    Maxgateways
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('maxgroups')}
                    onClick={this.searchBox.handleSort('maxgroups')}
                  >
                    Maxgroups
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('maxuplink')}
                    onClick={this.searchBox.handleSort('maxuplink')}
                  >
                    Maxuplink
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('maxdownlink')}
                    onClick={this.searchBox.handleSort('maxdownlink')}
                  >
                    Maxdownlink
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('cacheperiod')}
                    onClick={this.searchBox.handleSort('cacheperiod')}
                  >
                    Cacheperiod
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={this.searchBox.handleSorted('life')}
                    onClick={this.searchBox.handleSort('life')}
                  >
                    Life
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="unsortable"
                    key="header_actions"
                    textAlign="center"
                  >
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {idx(profileList, accessory =>
                  accessory.rows.map(row => (
                    <Table.Row key={`row-${row.id}`}>
                      <Table.Cell>
                        <Link
                          to={`${PAGES_PATH}/${CURR_PATH}/${idx(
                            row,
                            _ => _.id,
                          )}`}
                        >
                          <strong>{idx(row, _ => _.id)}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`${PAGES_PATH}/${CURR_PATH}/${idx(
                            row,
                            _ => _.id,
                          )}`}
                        >
                          <strong>{idx(row, _ => _.label)}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.role) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.maxdevices) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.maxgateways) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.maxgroups) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.maxuplink) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.maxdownlink) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.cacheperiod) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell>
                        {idx(row, _ => _.life) || EMPTY_STRING_VALUE}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Link
                          to={`${PAGES_PATH}/${CURR_PATH}/${idx(
                            row,
                            _ => _.id,
                          )}/edit`}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() =>
                            this.handleDeleteModal(row.id, row.label)
                          }
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )),
                )}
              </Table.Body>
            </Table>
          ) : (
            EMPTY_TABLE_MESSAGE
          )}
        </div>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>This action will delete this user profile</h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the label</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {this.state.label}
            </Label>
            <div>
              <Input
                name="check"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={this.state.label !== this.state.check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Container textAlign="center">
          {
            /*
             Pagination:
             SemanticUI  => https://react.semantic-ui.com/addons/pagination/
             Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
             */
            profileList && profileList.size > profileList.limit && (
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={
                  parseInt(profileList.offset / profileList.limit, 10) + 1
                }
                totalPages={Math.ceil(profileList.size / profileList.limit)}
                onPageChange={this.searchBox.handlePageChange}
              />
            )
          }
        </Container>
      </Fragment>
    );
  }
}

UsersProfileListTable.propTypes = {
  userProfileList: PropTypes.object,
  fetchUserProfilesList: PropTypes.func,
  destroyUserProfiles: PropTypes.func,
  destroyUserProfile: PropTypes.func,
  deleteUserProfiles: PropTypes.func,
  offset: PropTypes.object,
  destroyUserProfilesList: PropTypes.func,
  deleteUserProfile: PropTypes.func,
};

const UserProfilesList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersProfileListTable),
);

export { UserProfilesList };
