/** @format */

/* eslint-disable no-nested-ternary */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import idx from 'idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Header,
  Input,
  Label,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react';
import {
  deleteMulticastGroupDevices,
  destroyMulticastGroupDevices,
  fetchMulticastGroupDevices,
} from '../../actions/multicastGroupDevices';
import {
  cleanObject,
  EMPTY_TABLE_MESSAGE,
  formatKeys,
  searchMethod,
} from '../../lib/utilities';
import { DEFAULT_ELEMENTS_PER_PAGE, PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  multicastGroupDevices: state.multicastGroupDevices,
});

const mapDispatchToProps = dispatch => ({
  fetchMulticastGroupDevices: (mgid, params) => {
    dispatch(fetchMulticastGroupDevices(mgid, params));
  },
  deleteMulticastGroupDevices: (multicastGroup, device) => {
    dispatch(deleteMulticastGroupDevices(multicastGroup, device));
  },
  destroyMulticastGroupDevices: () => {
    dispatch(destroyMulticastGroupDevices());
  },
});

class DvTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      offset: 0,
      limit: DEFAULT_ELEMENTS_PER_PAGE,
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
      searchModel: {},
      sortModel: {
        label: '',
        direction: null,
        /* asc || desc || null */
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetch = this.fetch.bind(this);

    this.searchBox = {
      doFetch: queryParams => {
        const qs = queryParams ? queryParams.join('&') : '';
        this.props.fetchMulticastGroupDevices(this.props.match.params.mgid, {
          qs,
        });
      },

      handlePageChange: (e, { activePage }) => {
        const { multicastGroupDevices, offset } = this.props;
        const { searchModel, sortModel, limit } = this.state;

        const newOffset = (activePage - 1) * multicastGroupDevices.limit;

        const currOffset = newOffset || (offset > 0 ? offset : null);

        const queryParams = searchMethod.genQs(
          searchModel,
          sortModel,
          currOffset,
          limit,
        );

        this.setState({ offset: newOffset });
        this.searchBox.doFetch(queryParams);
      },

      handleSearch: (e, el, newSortModel) => {
        const { searchModel, sortModel, limit } = this.state;

        let currSortModel = null;

        if (newSortModel) currSortModel = { ...newSortModel };
        else if (sortModel.label) currSortModel = { ...sortModel };

        const queryParams = searchMethod.genQs(
          searchModel,
          currSortModel,
          null,
          limit,
        );
        // debugger
        if (newSortModel) this.setState({ sortModel: newSortModel });
        this.searchBox.doFetch(queryParams);
      },

      handleSearchChange: (e, { name, value }) => {
        const { searchModel } = this.state;
        const newSearchModel = { ...searchModel };
        if (name === 'expiry') {
          newSearchModel[name] = encodeURIComponent(
            moment(value).toISOString(),
          );
        } else if (value === '') {
          delete newSearchModel[name];
        } else {
          newSearchModel[name] = encodeURIComponent(value);
        }
        this.setState({ searchModel: newSearchModel });
      },

      handleSort: clickedColumn => () => {
        const { sortModel } = this.state;
        let direction = null;
        let label = '';
        if (sortModel.label === clickedColumn) {
          switch (sortModel.direction) {
            case 'asc':
              direction = 'desc';
              label = clickedColumn;
              break;

            case 'desc':
              direction = null;
              break;

            default:
              direction = 'asc';
              label = clickedColumn;
          }
        } else {
          direction = 'asc';
          label = clickedColumn;
        }
        this.searchBox.handleSearch(null, null, {
          ...sortModel,
          label,
          direction,
        });
      },

      handleSorted: column => {
        const { sortModel } = this.state;
        if (sortModel.direction && sortModel.label === column) {
          return sortModel.direction === 'asc' ? 'descending' : 'ascending';
        }
        return null;
      },
    };
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.fetchMulticastGroupDevices(this.props.match.params.mgid, {
      qs: limit > 0 ? `limit=${limit}` : '',
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    if (this.props.multicastGroupDevices !== nextProps.multicastGroupDevices) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.props.destroyMulticastGroupDevices();
  }

  fetch(qs) {
    const { fetchDevicesSharedBy } = this.props;
    fetchDevicesSharedBy(qs);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
    });
  }

  handleDeleteModal(device) {
    this.setState({ modalOpen: true, device, deveui: device.deveui });
  }

  handleDelete() {
    const { device, limit } = this.state;
    this.props.deleteMulticastGroupDevices(
      this.props.match.params.mgid,
      device.id,
    );
    setTimeout(() => {
      this.props.fetchMulticastGroupDevices(this.props.match.params.mgid, {
        qs: limit > 0 ? `limit=${limit}` : '',
      });
    }, 300);

    this.setState({
      modalOpen: false,
      device: null,
      deveui: null,
      check: null,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handlePageChange(e, { activePage }) {
    const { multicastGroupDevices } = this.props;

    const params = cleanObject({ ...multicastGroupDevices }, [
      'filter',
      'sort',
      'offset',
      'limit',
    ]);
    params.offset = (activePage - 1) * multicastGroupDevices.limit;
    this.props.fetchMulticastGroupDevices(this.props.match.params.mgid, params);
  }

  render() {
    const { multicastGroupDevices, isLimited } = this.props;

    return (
      <Fragment>
        {multicastGroupDevices.rows && multicastGroupDevices.rows.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell key="headerLabel">Label</Table.HeaderCell>
                {!isLimited && (
                  <Table.HeaderCell key="headerApplication">
                    Application
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell key="headerDevEui">DevEui</Table.HeaderCell>
                {!isLimited && (
                  <>
                    <Table.HeaderCell key="headerDevAddr">
                      Dev Address
                    </Table.HeaderCell>
                    <Table.HeaderCell key="headerType">Type</Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell key="headerActions" textAlign="center">
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {idx(multicastGroupDevices, accessory =>
                accessory.rows.map(row => (
                  <Table.Row key={`row-${row.id}`}>
                    <Table.Cell key={`row-${row.id}-label`}>
                      <Link to={`${PAGES_PATH}/device/${idx(row, _ => _.id)}`}>
                        <strong>{idx(row, _ => _.label) || 'no name'}</strong>
                      </Link>
                    </Table.Cell>

                    {!isLimited && (
                      <Table.Cell key={`row-${row.id}-application`}>
                        {idx(row, _ => _.application.label)}
                      </Table.Cell>
                    )}

                    <Table.Cell key={`row-${row.id}-deveui`}>
                      {idx(row, _ => formatKeys(_.deveui))}
                    </Table.Cell>

                    {!isLimited && (
                      <>
                        <Table.Cell key={`row-${row.id}-devaddr`}>
                          {idx(row, _ => formatKeys(_.devaddr))}
                        </Table.Cell>

                        <Table.Cell key={`row-${row.id}-type`}>
                          {idx(row, _ => _.type)}
                        </Table.Cell>
                      </>
                    )}

                    {
                      <Table.Cell
                        key={`row-${row.id}-tools`}
                        textAlign="center"
                      >
                        <Link
                          to="#"
                          onClick={() => this.handleDeleteModal(row)}
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Link>
                      </Table.Cell>
                    }
                  </Table.Row>
                )),
              )}
            </Table.Body>
          </Table>
        ) : !isLimited ? (
          EMPTY_TABLE_MESSAGE
        ) : (
          ''
        )}

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <div style={{ width: '40%' }}>
            <SVG src="/assets/images/robotRYS.svg" />
          </div>
          <Header as="h1" content="Are you sure??" />
          <Modal.Content>
            <h3>This action will remove this device from a multicast group.</h3>
            <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
            <p>To proceed, please fill the field with the Device EUI</p>
            <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
              {this.state.deveui}
            </Label>
            <div>
              <Input
                name="check"
                label="deveui"
                placeholder="..."
                width={8}
                onChange={this.handleChange}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleDelete}
              color="red"
              disabled={this.state.deveui !== this.state.check}
            >
              Proceed
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </Modal.Actions>
        </Modal>

        <Container textAlign="center">
          {/*
              Pagination:
              SemanticUI  => https://react.semantic-ui.com/addons/pagination/
              Web Service => https://documenter.getpostman.com/view/7664580/SW11XeM5?version=8ed314ff-c1e3-48cf-b62e-0ba509032cb9#common
              */}
          {multicastGroupDevices &&
            multicastGroupDevices.size > multicastGroupDevices.limit && (
              <Pagination
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                activePage={
                  parseInt(
                    multicastGroupDevices.offset / multicastGroupDevices.limit,
                    10,
                  ) + 1
                }
                totalPages={Math.ceil(
                  multicastGroupDevices.size / multicastGroupDevices.limit,
                )}
                onPageChange={this.searchBox.handlePageChange}
              />
            )}
        </Container>
      </Fragment>
    );
  }
}

DvTable.propTypes = {
  match: PropTypes.object,
  multicastGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multicastGroupDevices: PropTypes.object,
  fetchMulticastGroupDevices: PropTypes.func,
  destroyMulticastGroupDevices: PropTypes.func,
  deleteMulticastGroupDevices: PropTypes.func,
  offset: PropTypes.object,
  fetchDevicesSharedBy: PropTypes.func,
  isLimited: PropTypes.bool,
};

const MulticastGroupDevicesTable = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DvTable),
);

export { MulticastGroupDevicesTable };
